
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat, numberFormat } from '@/utils/helper/format';
import { ServicesTypes } from '@/core/data/constance';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useRoute } from 'vue-router';

const breadcrumbs = ['Cancel Report', ['Cancel List']];
const tableHeader = [
  {
    name: 'Order ID',
    key: 'id',
    sortable: true,
  },
  {
    name: 'Order Type',
    key: 'type',
    sortable: true,
  },
  {
    name: 'Store',
    key: 'store',
    sortable: false,
  },
  {
    name: 'Customer',
    key: 'customer',
    sortable: false,
  },
  {
    name: 'Pay Method',
    key: 'payment',
    sortable: false,
  },
  {
    name: 'Date Cancel',
    key: 'updatedAt',
    sortable: true,
  },
  {
    name: 'Price',
    key: 'amountPrice',
    sortable: true,
  },
  {
    name: 'More Details',
    key: 'details',
    sortable: false,
  },
];

export default {
  name: 'Commission List',
  components: {
    Datatable,
    ConfirmModal,
  },
  setup() {
    const route = useRoute();
    const paymentMethod = ref<any>(null);
    const refundId = ref<any>(null);
    const modelRef = ref<any>(null);
    const tableRef = ref<any>(null);
    const state = reactive({
      tableData: [],
      loading: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        state.currentPage = page;
        state.rowsPerPage = rows;

        if (state.rowsPerPage + 1 > state.total) {
          state.currentPage = 1;
        }

        await fetch(state.currentPage, state.rowsPerPage);
      }
    );

    watch(
      () => route.query,
      (query) => {
        const { paymentMethod: method } = query;
        paymentMethod.value = method ? `${method}` : null;
      }
    );

    const fetch = async (page = 1, rowsPerPage = 10) => {
      try {
        const queryCondition: any = {
          page,
          pageSize: rowsPerPage,
        };

        if (paymentMethod.value) {
          queryCondition['paymentMethod'] = paymentMethod.value;
        }

        // const { data } = await ApiService.get(
        //   `/order?${new URLSearchParams(queryCondition)}`
        // );

        const { data } = await ApiService.get(
          `/order/refund-list?${new URLSearchParams(queryCondition)}`
        );

        if (data.status) {
          state.total = data.total;
          state.tableData = data.datas;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>,
        {}
      );
      const { paymentMethod: method } = route.query;
      paymentMethod.value = method ? `${method}` : null;
      await fetch();
    });

    const confirmRefund = (id: string) => {
      refundId.value = id;
    };

    const refundOrder = async (order: any) => {
      try {
        await ApiService.post(`/order/refund/${refundId.value}`, {
          refund: {
            method: order.payment?.method || '-',
            amount: order.price?.customer || 0,
            reason: '',
          },
        });

        return Swal.fire({
          text: 'You have successfully refunded order!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-primary',
          },
        }).then(async () => {
          await fetch();
        });
      } catch (error) {
        Swal.fire({
          text: `cannot refund`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    };

    return {
      ...toRefs(state),
      tableHeader,
      breadcrumbs,
      tableRef,
      dateFormat,
      numberFormat,
      ServicesTypes,
      modelRef,
      confirmRefund,
      refundOrder,
    };
  },
};
